//react
import React from 'react'
import { useNavigate } from 'react-router'

//material-ui
import { Box, Button, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import { ReactComponent as SummaIcon } from '../utils/Summa-logo-white.svg'

import { Link as LinkRouter } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 20px 20px 20px',
  },
  header_text: {
    align: 'left',
    marginLeft: 20,
  },
  title_text: {
    flexGrow: 1,
  },

  root_nonauth: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 20px 20px 20px',
  },
  header_text_nonauth: {
    align: 'left',
    marginLeft: 20,
  },
})

export default function Header(props) {
  const navigate = useNavigate()
  const classes = useStyles()
  const authenticated = props.authenticated
  const title = props.title

  return (
    <>
      <Box className={authenticated ? classes.root : classes.root_nonauth}>
        <SummaIcon style={{ height: '40' }} />
        <Typography
          variant="body2"
          color="textPrimary"
          className={
            authenticated ? classes.header_text : classes.header_text_nonauth
          }
        >
          Training for hormones
        </Typography>
        {authenticated && (
          <Typography
            variant="h6"
            color="textPrimary"
            align="center"
            className={classes.title_text}
          >
            {title ? title : ''}
          </Typography>
        )}
        {authenticated && title && (
          <>
            <Button onClick={() => navigate(-1)} color="primary">
              Back
            </Button>
            <Button component={LinkRouter} to="/" color="primary">
              Dashboard
            </Button>
          </>
        )}
        {authenticated && !title && (
          <Button component={LinkRouter} to="/logout" color="primary">
            Logout
          </Button>
        )}
      </Box>
      <Divider />
    </>
  )
}
