import { React, useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

//mui
import { Button, Grid, TextField, Typography } from '@mui/material'

//auth
import { useAuth } from '../contexts/AuthContext'

export default function LoginFinish() {
  const auth = useAuth()
  let navigate = useNavigate()

  const STATE_USER_INNPUT_ALLOWED = 0
  const STATE_LOGGING_IN = 1
  const STATE_LOGGED_IN = 2
  const STATE_ERROR = 3

  //title texts for each state
  const title = {}
  title[STATE_USER_INNPUT_ALLOWED] =
    'Please re-enter your email address used for logging in.'
  title[STATE_LOGGING_IN] = 'Please wait while we are logging you in...'
  title[STATE_LOGGED_IN] =
    'You have been logged in succesfully. You will be taken to homepage momentarily.'
  title[STATE_ERROR] =
    'An error occured while logging you in. Proceed to login page and try again.'

  const [state, setState] = useState(STATE_USER_INNPUT_ALLOWED)
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (
      auth.validateLink(window.location.href) &&
      state === STATE_USER_INNPUT_ALLOWED //this prevents double useEffect calls in development React Strict mode
    ) {
      let email = window.localStorage.getItem('emailForSignIn')
      if (email) {
        setState(STATE_LOGGING_IN)

        auth
          .loginWithLink(email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn')
            setState(STATE_LOGGED_IN)
            setTimeout(() => {
              navigate('/')
            }, 3000)
          })
          .catch((error) => {
            setState(STATE_ERROR)
          })
      } else {
        setState(STATE_USER_INNPUT_ALLOWED)
      }
    } else {
      setState(STATE_ERROR)
    }
  }, [])

  const handleLogin = () => {
    if (state === STATE_USER_INNPUT_ALLOWED) {
      setState(STATE_LOGGING_IN)

      auth
        .loginWithLink(email, window.location.href)
        .then((result) => {
          return result.user.getIdToken()
        })
        .then((result) => {
          setState(STATE_LOGGED_IN)
        })
        .catch((error) => {
          setState(STATE_ERROR)
        })
    } else if (state === STATE_ERROR) {
      navigate('/login')
    }
  }

  return (
    <Layout>
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={1} sm={3} md={4} />
        <Grid item xs={10} sm={6} md={4}>
          <Typography
            variant="body1"
            color="textPrimary"
            align="center"
            style={{ margin: '10px auto 20px auto' }}
          >
            {title[state]}
          </Typography>
          {state === STATE_LOGGED_IN && (
            <Button
              variant="text"
              color="secondary"
              component={RouterLink}
              to="/"
              size="small"
              fullWidth
              style={{ margin: '40px auto 20px auto' }}
            >
              I don't want to wait, take me to homepage now
            </Button>
          )}
          {state === STATE_USER_INNPUT_ALLOWED && (
            <TextField
              name="textfield_email"
              value={email}
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
            />
          )}
          {(state === STATE_USER_INNPUT_ALLOWED || state === STATE_ERROR) && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              style={{ margin: '40px auto 20px auto' }}
              onClick={handleLogin}
            >
              Login
            </Button>
          )}
        </Grid>
        <Grid item xs={1} sm={3} md={4} />
      </Grid>
    </Layout>
  )
}
