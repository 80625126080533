import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

//axios
import axios from 'axios'

//components
import Layout from '../components/Layout'

//mui
import { Grid, Typography } from '@mui/material'
import BatchDetails from '../components/BatchDetails'
import {
  BatchActionsNew,
  BatchActionsShelf,
  BatchActionsSubject,
  BatchActionsLab,
  BatchActionsReport,
} from '../components/BatchActions'
import BatchAI from '../components/BatchAI'

export default function Batch() {
  let params = useParams()
  let batch_id = params.batch_id

  const [batch, setBatch] = useState(null)

  const [state, setState] = useState('loading')

  useEffect(() => {
    if (batch_id === undefined) return

    axios
      .get('batch/' + batch_id)
      .then((response) => {
        setBatch(response.data)
        setState('ready')
      })
      .catch((error) => console.log(error))
  }, [batch_id])

  return (
    <Layout title="Sample Set">
      {state === 'loading' && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
          style={{
            flex: 1,
            textAlign: 'center',
            margin: '20px 20px 20px 20px',
          }}
        >
          Please wait while we load data....
        </Typography>
      )}

      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {state === 'ready' && <BatchDetails batch={batch} />}
          {state === 'ready' && batch.state === '@new' && (
            <BatchActionsNew batch_id={batch.id} />
          )}
          {state === 'ready' && batch.state === '@shelf' && (
            <BatchActionsShelf batch_id={batch.id} />
          )}
          {state === 'ready' && batch.state === '@subject' && (
            <BatchActionsSubject batch_id={batch.id} />
          )}
          {state === 'ready' && batch.state === '@lab' && (
            <BatchActionsLab batch_id={batch.id} />
          )}
          {state === 'ready' && batch.state === '@report' && (
            <BatchActionsReport batch_id={batch.id} />
          )}
          {state === 'ready' && <BatchAI batch={batch} />}
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Layout>
  )
}
