import { React, useState } from 'react'

import { useNavigate } from 'react-router'

import PolynomialRegression from 'ml-regression-polynomial'

//material ui
import { Paper, Grid, Stepper, Step, StepLabel } from '@mui/material'

//components
import Layout from '../components/Layout'
import LabNewTestS1 from '../components/LabNewTestS1'
import LabNewTestS2 from '../components/LabNewTestS2'

import {
  LabNewTestS3,
  defaultStandards,
  defualtControls,
  TEST_TYPE_COR,
} from '../components/LabNewTestS3'

import LabNewTestS4 from '../components/LabNewTestS4'
import LabNewTestS5 from '../components/LabNewTestS5'

const STATE_SELECT_KITS = 0

export default function LabNewTest() {
  const navigate = useNavigate()
  const [activeState, setActiveState] = useState(STATE_SELECT_KITS)

  const [testSet, setTestSet] = useState({
    type: TEST_TYPE_COR,
    standards: defaultStandards[TEST_TYPE_COR],
    controls: defualtControls[TEST_TYPE_COR],
    batches: null,
    fileContent: null,
    comments: '',
    welles: null,
    results: null,
  })

  const steps = [
    'Select sample kits',
    'Assign samples',
    'Set standards',
    'ELISA file and comments',
    'Upload',
  ]

  const onNext = () => {
    setActiveState(activeState + 1)
  }

  const onNextS1 = (batches, autoallocate) => {
    let selectedBatches = []

    if (batches) {
      batches.forEach((element) => {
        let batch = {}
        batch.id = element.id
        batch.samples = []

        element.samples.forEach((sample) => {
          let short_id = batch.id.substring(batch.id.length - 2) + '_' + sample
          batch.samples.push({ short_id, location: null })
        })
        selectedBatches.push(batch)
      })
    }

    setTestSet({
      ...testSet,
      batches: selectedBatches,
      autoallocate: autoallocate,
    })

    onNext()
  }

  const onNextS3 = (type, standards, controls) => {
    setTestSet({
      ...testSet,
      type,
      standards,
      controls,
    })

    onNext()
  }

  const onNextS4 = (fileContent, comments) => {
    let welles = []

    if (fileContent) {
      let elements = fileContent.split(' ')
      //A
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[25 + i]))
      //B
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[38 + i]))
      //C
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[51 + i]))
      //D
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[64 + i]))
      //E
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[77 + i]))
      //F
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[90 + i]))
      //G
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[103 + i]))
      //H
      for (let i = 0; i < 12; i++) welles.push(parseFloat(elements[116 + i]))
    }

    //calculate results
    let results = calculateResults(
      testSet.type,
      testSet.standards,
      testSet.controls,
      welles,
    )

    setTestSet({ ...testSet, fileContent, comments, welles, results })

    onNext()
  }

  const onNextS5 = () => {
    navigate('/lab')
  }

  //assumes at least one of the number is correct
  const calculateAverage = (well1, well2) => {
    if (isNaN(well1)) return well2
    if (isNaN(well2)) return well1

    return (well1 + well2) / 2
  }

  const calculateResults = (type, standards, controls, welles) => {
    const rowA = -1
    const rowB = 11
    const rowC = 23
    const rowD = 35
    const rowE = 47
    const rowF = 59
    const rowG = 71
    const rowH = 83

    const multiplyBy = type ? 3.47 : 2.76

    let result = {
      summary: '',
      data: [],
    }

    //checks standards if there are any of the pairs is not a numbers
    if (
      (isNaN(welles[rowA + 1]) && isNaN(welles[rowB + 1])) ||
      (isNaN(welles[rowC + 1]) && isNaN(welles[rowD + 1])) ||
      (isNaN(welles[rowE + 1]) && isNaN(welles[rowF + 1])) ||
      (isNaN(welles[rowG + 1]) && isNaN(welles[rowH + 1])) ||
      (isNaN(welles[rowA + 2]) && isNaN(welles[rowB + 2])) ||
      (isNaN(welles[rowC + 2]) && isNaN(welles[rowD + 2]))
    ) {
      result.summary +=
        'FAILURE error when analysing standards\nAt least one of the standards value has errors in both readings.'
      return result
    }

    //checks controls if there are any not a numbers
    //for now enforce all controls are without errors
    if (
      (isNaN(welles[rowE + 2]) && isNaN(welles[rowF + 2])) ||
      (isNaN(welles[rowG + 2]) && isNaN(welles[rowH + 2]))
    ) {
      result.summary +=
        'FAILURE error when analysing controls\nAt least one of the controls value has errors in both readings.'
      return result
    }

    var standardsData_x = []
    standardsData_x[0] =
      1 / calculateAverage(welles[rowA + 1], welles[rowB + 1])
    standardsData_x[1] =
      1 / calculateAverage(welles[rowC + 1], welles[rowD + 1])
    standardsData_x[2] =
      1 / calculateAverage(welles[rowE + 1], welles[rowF + 1])
    standardsData_x[3] =
      1 / calculateAverage(welles[rowG + 1], welles[rowH + 1])
    standardsData_x[4] =
      1 / calculateAverage(welles[rowA + 2], welles[rowB + 2])
    standardsData_x[5] =
      1 / calculateAverage(welles[rowC + 2], welles[rowD + 2])

    var standardsData_y = []
    standardsData_y[0] = standards.a1b1 * multiplyBy
    standardsData_y[1] = standards.c1b1 * multiplyBy
    standardsData_y[2] = standards.e1f1 * multiplyBy
    standardsData_y[3] = standards.g1h1 * multiplyBy
    standardsData_y[4] = standards.a2b2 * multiplyBy
    standardsData_y[5] = standards.c2d2 * multiplyBy

    const regression = new PolynomialRegression(
      standardsData_x,
      standardsData_y,
      3,
    )

    result.summary += 'TEST DETAILS:\n'
    result.summary += 'Function: ' + regression.toString(3) + '\n'

    let control1 = regression.predict(
      1 / calculateAverage(welles[rowE + 2], welles[rowF + 2]),
    )

    if (
      control1 >= controls.c1_min * multiplyBy &&
      control1 <= controls.c1_max * multiplyBy
    ) {
      result.summary += 'Control 1: ' + control1.toFixed(2) + '\n'
      result.summary +=
        'Control 1: PASS with ' +
        ((control1 / (controls.c1_exact * multiplyBy)) * 100).toFixed(2) +
        '% vs. exact\n'
    } else {
      result.summary += 'Control 1: ' + control1.toFixed(2) + '\n'
      result.summary +=
        'Control 1: FAILED with ' +
        ((control1 / (controls.c1_exact * multiplyBy)) * 100).toFixed(2) +
        '% vs. exact\n'
    }

    let control2 = regression.predict(
      1 / calculateAverage(welles[rowG + 2], welles[rowH + 2]),
    )
    if (
      control2 >= controls.c2_min * multiplyBy &&
      control2 <= controls.c2_max * multiplyBy
    ) {
      result.summary += 'Control 2: ' + control2.toFixed(2) + '\n'
      result.summary +=
        'Control 2: PASS with ' +
        ((control2 / (controls.c2_exact * multiplyBy)) * 100).toFixed(2) +
        '% vs. exact\n'
    } else {
      result.summary += 'Control 2: ' + control2.toFixed(2) + '\n'
      result.summary +=
        'Control 2: FAILED with ' +
        ((control2 / (controls.c2_exact * multiplyBy)) * 100).toFixed(2) +
        '% vs. exact\n'
    }

    const minValue1A = standardsData_x[0]
    const maxValue1A = standardsData_x[5]
    let tooLow = 0
    let tooHigh = 0
    let error = 0

    for (let row = rowA; row < 90; row += 12) {
      for (let sample = 3; sample < 13; sample += 1) {
        let value = 1 / welles[row + sample]
        if (isNaN(value)) {
          result.data.push('Error')
          error++
        } else if (value < minValue1A) {
          result.data.push('Too low')
          tooLow++
        } else if (value > maxValue1A) {
          result.data.push('Too high')
          tooHigh++
        } else {
          result.data.push(regression.predict(value))
        }
      }
    }

    result.summary +=
      '80 wells read with:\n' +
      error +
      ' error(s);\n' +
      tooLow +
      ' too low value(s);\n' +
      tooHigh +
      ' too high value(s)\n'

    return result
  }

  return (
    <Layout title="New Lab Test">
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Paper>
            <Stepper activeStep={activeState} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} style={{ margin: '30px auto 30px auto' }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeState === 0 && <LabNewTestS1 onNext={onNextS1} />}
            {activeState === 1 && (
              <LabNewTestS2
                batches={testSet.batches}
                autoallocate={testSet.autoallocate}
                onNext={onNext}
              />
            )}
            {activeState === 2 && (
              <LabNewTestS3 testSet={testSet} onNext={onNextS3} />
            )}
            {activeState === 3 && (
              <LabNewTestS4 testSet={testSet} onNext={onNextS4} />
            )}
            {activeState === 4 && (
              <LabNewTestS5 testSet={testSet} onNext={onNextS5} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Layout>
  )
}
