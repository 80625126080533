import './App.css'
import React, { Component } from 'react'

//react-router-dom
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//material ui
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles'

//contexts
import { AuthProvider } from './contexts/AuthContext'

//utils
import themeOptions from './utils/theme.js'

import axios from 'axios'

//components
import PrivateRoute from './components/PrivateRoute'

//pages
import Home from './pages/Home'
import Login from './pages/Login'
import LoginFinish from './pages/LoginFinish'
import Logout from './pages/Logout'
import Sample from './pages/Sample'
import Page404 from './pages/Page404'
import Protocols from './pages/Protocols'
import ProtocolsPrint from './pages/ProtocolsPrint'
import ProtocolsBarcode from './pages/ProtocolsBarcode'
import SampleFirst from './pages/SampleFirst'
import ProtocolsNewBatch from './pages/ProtocolsNewBatch'
import Batch from './pages/Batch'
import Lab from './pages/Lab'
import LabNewTest from './pages/LabNewTest'
import RedirectOura from './pages/RedirectOura'
import ConnectOura from './pages/ConnectOura'
import ConnectWhoop from './pages/ConnectWhoop'
import RedirectWhoop from './pages/RedirectWhoop'

axios.defaults.baseURL =
  process.env.REACT_APP_TARGET === 'production'
    ? process.env.REACT_APP_PROD_API_SERVER
    : process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_LOC_API_SERVER
    : process.env.REACT_APP_DEV_API_SERVER

class App extends Component {
  customTheme = createTheme(adaptV4Theme(themeOptions))

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={this.customTheme}>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route path="loginfinish" element={<LoginFinish />} />
                <Route path="logout" element={<Logout />} />
                <Route path="sample" element={<Sample />}>
                  <Route path=":sample_id" element={<Sample />} />
                </Route>
                <Route path="samplefirst" element={<SampleFirst />}>
                  <Route path=":batch_id" element={<SampleFirst />} />
                </Route>
                <Route path="redirect_oura" element={<RedirectOura />}></Route>
                <Route path="connect_oura" element={<ConnectOura />}></Route>
                <Route path="connect_whoop" element={<ConnectWhoop />}></Route>
                <Route
                  path="redirect_whoop"
                  element={<RedirectWhoop />}
                ></Route>

                <Route path="*" element={<Page404 />} />

                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="protocols"
                  element={
                    <PrivateRoute>
                      <Protocols />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="protocols/print"
                  element={
                    <PrivateRoute>
                      <ProtocolsPrint />
                    </PrivateRoute>
                  }
                >
                  <Route path=":batch_id" element={<ProtocolsPrint />} />
                </Route>
                <Route
                  path="protocols/barcode"
                  element={
                    <PrivateRoute>
                      <ProtocolsBarcode />
                    </PrivateRoute>
                  }
                >
                  <Route path=":batch_id" element={<ProtocolsBarcode />} />
                </Route>
                <Route
                  path="protocols/newbatch"
                  element={
                    <PrivateRoute>
                      <ProtocolsNewBatch />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="batch"
                  element={
                    <PrivateRoute>
                      <Batch />
                    </PrivateRoute>
                  }
                >
                  <Route path=":batch_id" element={<Batch />} />
                </Route>

                <Route
                  path="lab"
                  element={
                    <PrivateRoute>
                      <Lab />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="lab/newtest"
                  element={
                    <PrivateRoute>
                      <LabNewTest />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

export default App
